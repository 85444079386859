import { useEffect, useContext } from 'react';
import ABeagleContext from '../../contexts/abeagle';
import DestinationContext from '../../contexts/destination';
import { attachClientTimeSpentHandler } from "./client-event-tracking";

export function useTimeSpentTracking(buzz) {
  const { getExperimentValue } = useContext(ABeagleContext);
  const { destination } = useContext(DestinationContext);

  const time_spent_variant = getExperimentValue('TimeSpentRO_4', {
    rejectErrors: false
  });

  useEffect(() => {
    let time_spent_handler = () => {};
    if (time_spent_variant === 'on') {
      time_spent_handler = attachClientTimeSpentHandler(buzz);
    }
    return time_spent_handler;

  }, [buzz, destination, time_spent_variant]);
}
