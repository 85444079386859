import { useCallback } from 'react';
import { trackClientContentAction } from './client-event-tracking';

/**
 * Returns function for tracking events
 *
 * Import at the top of the component/file as:
 * const trackingFn = useNavActionTracking(buzz)
 *
 * Pass the tracking fn to the comments component via react props
 * eventData will be provided by the component when it fires the event
 *
 * @returns {Function}
 */
export function useNavActionTracking(buzz) {
  return useCallback(
    data => {
      trackClientContentAction(buzz, {
        action_type: data.actionType,
        action_value: data.actionValue,
        item_name: data.itemName,
        item_type: data.itemType,
        position_in_unit: 0,
        subunit_name: data.subunitName || '',
        subunit_type: data.subunitType || '',
        unit_name: data.unitName,
        unit_type: data.unitType,
      });
    },
    [buzz]
  );
}
