import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CommentsProvider } from '../../contexts/comments';
import { showComments } from '../../utils/showComments';

export default function CommentsManager({ buzz, children }) {
  const [isCommentsPanelOpen, toggleCommentsPanel] = useState(false);

  return (
    <CommentsProvider
      value={{
        commentsEnabled: showComments(buzz),
        isCommentsPanelOpen,
        toggleCommentsPanel
      }}>
        {children}
    </CommentsProvider>
  );
}

CommentsManager.propTypes = {
  buzz: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
