import { useEffect } from 'react';
import { trackClientPerformance } from './client-event-tracking';

/**
 * Sets up and initializes tracking of performance metrics.
 * @param {Object} buzz
 */
export function usePerformanceTracking(buzz) {
  useEffect(() => {
    trackClientPerformance(buzz);
    // The empty dependency array is used below as we only want to send
    // load-performance metrics on the initial load.
    // This is because load timings after a dynamic route change are not as representative
    // given hydration (and other operations) will have already occurred
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}
