import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import stickyRegistry from '@buzzfeed/bf-utils/lib/sticky-registry';
import { useBreakpoint } from '@buzzfeed/react-components';
import StickyContext from '../../contexts/sticky';

// threshold (as a fraction of viewport height) for when the article is considered to be scrolled through
// and either the ad or newsletter popup can be sticky
const ARTICLE_BOTTOM_DIST = 1;

/**
 * Context provider
 */
const StickyManager = function({ buzz, children }) {
  const observerInstance = useRef(null);
  const isScrollPastThreshold = useRef(false);
  const [isReady, setIsReady] = useState(false);

  const stickyItems = useRef({
    top: {
      canStick: false,
      isSticky: false,
      callback: () => false,
      isReady: false,
    },
    bottom: {
      canStick: false,
      isSticky: false,
      callback: () => false,
      isReady: false,
    },
  });
  const prevBuzzId = useRef(null);

  const isXs = !useBreakpoint('(min-width:40rem)');
  // always have awareness sticky on mweb now, even on quizzes
  const isAdSticky = isXs;

  /**
   * Internal function
   * Determines which of the two registered components (top or bottom), if any, can be sticky at a given moment,
   * queries the eligible candidate whether it will actually be sticky,
   * notifies the other component that it should not be sticky.
   * Does not make any CSS/DOM changes, that is supposed to be handled by the components themselves.
   * Is called whenever any of the following happens:
   * - a component calls the manager's `notify`
   * - the intersection observer signals that the scrolling threshold is passed
   */
  const queryStickyItems = useCallback(
    (/*reason*/) => {
      const items = stickyItems.current;
      // whether the sticky alignment changed
      let hasChanged = false;

      let topWillStick;
      if (
        // in experiment and sticky ad is not dismissed
        items.top.canStick &&
        // above threshold
        (!isScrollPastThreshold.current ||
          // newsletter popup is dismissed
          !items.bottom.isSticky)
      ) {
        topWillStick = items.top.callback({ shouldStick: true });
      } else {
        items.top.callback({ shouldStick: false });
        topWillStick = false;
      }
      if (!hasChanged) {
        hasChanged = items.top.isSticky !== topWillStick;
      }
      items.top.isSticky = topWillStick;

      let bottomWillStick;
      if (
        // newsletter popup is not dismissed
        items.bottom.canStick &&
        // scrolled past threshold
        (isScrollPastThreshold.current ||
          // not in experiment or sticky ad is dismissed
          !items.top.isSticky)
      ) {
        bottomWillStick = items.bottom.callback({ shouldStick: true });
      } else {
        items.bottom.callback({ shouldStick: false });
        bottomWillStick = false;
      }
      if (!hasChanged) {
        hasChanged = bottomWillStick !== items.bottom.isSticky;
      }
      items.bottom.isSticky = bottomWillStick;

      // should be safe unless callbacks call `notify` (or there will be an infinite loop if they do)
      if (hasChanged) {
        queryStickyItems('recursive');
      }
    },
    []
  );

  /**
   * Public member of context value
   * Registers a component with the manager;
   * requests the manager to determine which component (top or bottom) can be sticky.
   * *Must* be called by both top and bottom components, even if either of them is not going to be sticky
   * (in which case `canStick` should be set to `false`).
   * Can be called multiple times by the same component (the new settings object overwrites the previous one).
   * @param {'top'|'bottom'} type - location in viewport that the component wants to occupy when it's sticky
   * @param {Boolean} settings.canStick - whether the component can be sticky at all
   * (e.g. it can't if it has been dismissed by user)
   * @param {notifyCallback} settings.callback - called by the manager when it queries registered components
   *
   * @callback notifyCallback
   * Component's callback that decides whether the component will be sticky when queried by the manager;
   * also triggers any CSS/DOM updates needed for the component to become sticky.
   * *Must not* call the manager's `notify` as it will lead to an infinite loop!
   * @param {Boolean} params.shouldStick - whether the component is allowed to be sticky by the manager
   * @returns {Boolean} - whether the component will actually be sticky
   * (the return value is respected by the manager only when the `shouldStick` argument is `true`)
   */
  const notify = useCallback(
    (type, { canStick, callback }) => {
      if (!(type in stickyItems.current)) {
        throw new Error('Type must be one of `top` or `bottom`');
      }
      const item = stickyItems.current[type];
      if (!(callback instanceof Function)) {
        callback = item ? item.callback : () => false;
      }
      // mark this item as ready; overwrite `canStick` and `callback`, if present
      stickyItems.current[type] = {
        ...item,
        isReady: true,
        canStick,
        callback: callback,
      };

      if (
        !isReady &&
        Object.values(stickyItems.current).some(i => i.isReady)
      ) {
        setIsReady(true);
      }
      // if `setIsReady` has been called above, `isReady` will still be false at this point
      // because state is set asynchronously, so `queryStickyItems` will not be called here
      // (but will be called in the effect that reacts to `isReady` changes)
      if (isReady) {
        queryStickyItems('notify');
      }
    },
    [isReady, queryStickyItems]
  );

  /**
   * Public member of context value
   * Initiates an intersection observer that watches for
   * whether the scroll position is above or below the threshold.
   * @param {Element} articleBottomAnchor - DOM element that indicates the end of the main article content
   * (a 1px-tall element)
   */
  const observeArticleBottom = useCallback(
    articleBottomAnchor => {
      // do not create an intersection observer when not in experiment (since ad will not be sticky at all)
      if (!isAdSticky) {
        return;
      }
      // do not create an intersection observer until both sticky ad and newsletter popup are ready
      if (!isReady) {
        return;
      }

      if (observerInstance.current) {
        observerInstance.current.disconnect();
      }
      // there will be one entry because one element is being observed
      const observer = (observerInstance.current = new IntersectionObserver(
        ([entry]) => {
          const isPastThreshold =
            // if it's intersecting, scroll position is close to the bottom of the article
            // (within 1 viewport height above),
            // which means it's past threshold
            entry.isIntersecting ||
            // if it's not intersecting, it can be either because scroll position is too far up,
            // in which case threshold is not yet reached,
            // OR because the article bottom element itself is above the viewport top,
            // in which case scroll position is past it and threshold
            entry.boundingClientRect.bottom < entry.rootBounds.top;
          const hasChanged = isScrollPastThreshold.current !== isPastThreshold;
          isScrollPastThreshold.current = isPastThreshold;
          if (hasChanged) {
            queryStickyItems('IntersectionObserver');
          }
        },
        {
          rootMargin: `0px 0px ${document.documentElement.clientHeight *
            ARTICLE_BOTTOM_DIST}px 0px`,
          threshold: 0,
        }
      ));
      observer.observe(articleBottomAnchor);
    },
    [isReady, queryStickyItems]
  );

  /** Context value */
  const [context, setContext] = useState({
    notify,
    observeArticleBottom,
  });

  /**
   * Updates the context value whenever any of the callbacks has changed
   */
  useEffect(() => {
    setContext({
      notify,
      observeArticleBottom,
    });
  }, [notify, observeArticleBottom]);

  /** SPA */
  useEffect(() => {
    // sticky registry is not directly related to the sticky manager (although the ad does use it),
    // but it has to be reset somewhere, and this is a good enough place
    if (prevBuzzId.current && prevBuzzId.current !== buzz.id) {
      stickyRegistry.reset();
      prevBuzzId.current = buzz.id;
    }

    stickyItems.current = {
      top: {
        canStick: false,
        isSticky: false,
        callback: () => false,
        isReady: false,
      },
      bottom: {
        canStick: false,
        isSticky: false,
        callback: () => false,
        isReady: false,
      },
    };
    isScrollPastThreshold.current = false;
    if (observerInstance.current) {
      observerInstance.current.disconnect();
    }
    observerInstance.current = null;
    setIsReady(false);
  }, [buzz.id]);

  /**
   * The initial `queryStickyItems` call once both `top` and `bottom` sticky components are ready
   */
  useEffect(() => {
    if (isAdSticky && isReady) {
      document.body.classList.add('sticky-ad-exp');
      // should not be called more than once (see the effect above)
      queryStickyItems('initial');
    } else if (!isAdSticky && isReady) {
      document.body.classList.remove('sticky-ad-exp');
      queryStickyItems('control');
    }
  }, [isAdSticky, isReady, queryStickyItems]);

  return (
    <StickyContext.Provider value={context}>{children}</StickyContext.Provider>
  );
};

export default StickyManager;
