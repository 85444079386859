import { useEffect } from 'react';
import session from './session';

export function useSessionTracking(buzzId) {
  useEffect(() => {
    if (!buzzId) {
      return;
    }

    const addSessionBuzzId = async () => {
      const { buzzes } = await session();
      buzzes.add(buzzId);
    };
    addSessionBuzzId();
  }, [buzzId]);
}
