import { useContext, useEffect, useState } from 'react';
import ABeagleContext from "../../contexts/abeagle";

// https://buzzfeed.atlassian.net/browse/RT-882
// https://buzzfeed.atlassian.net/browse/RT-883

export default function Wunderkind() {
  const { experiments, getFeatureFlagValue, getExperimentValue } = useContext(ABeagleContext);
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    if (!experiments.loaded) {
      return;
    }
    const isEnabledUK = getFeatureFlagValue('ads_wunderkind_uk');
    const isEnabledNews = getFeatureFlagValue('ads_wunderkind_news');
    const isEnabledDesktop = getFeatureFlagValue('RT-1328-Wunderkind-on-desktop');
    const wunderkindExperimentValue = getExperimentValue('RT-1257-Wunderkind_desktop_only', {
      rejectErrors: false,
      defaultVariantIfUnbucketed: null,
    });

    if (hasLoaded || (!isEnabledUK && !isEnabledNews && !isEnabledDesktop)) return;

    if (wunderkindExperimentValue === 'no_wunderkind') return;

    const ID = isEnabledNews ? '6846' : (isEnabledUK ? '6004' : '6692');

    const snippet = `
      (function(d) {
        var e = d.createElement('script');
        e.src = d.location.protocol + '//tag.wknd.ai/${ID}/i.js';
        e.async = true;
        d.getElementsByTagName("head")[0].appendChild(e);
      }(document));
    `;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = snippet;
    document.head.appendChild(script);
    setHasLoaded(true);
  }, [getFeatureFlagValue, getExperimentValue, experiments]);

  return null;
}
