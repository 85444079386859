import { useEffect, useRef } from 'react';
import { trackClientScrollDepth } from './client-event-tracking';
import { scrollY } from '../../utils/scroll';

/**
 * Render hook:
 * attaches event listeners for `unload` and `visibilitychange` DOM events
 * in order to track 2 `web_scroll` events: `exit_depth` and `distraction_depth` respectively
 * `distraction_depth` will not be tracked once `exit_depth` is sent
 */
export function useScrollDepthTracking(buzz) {
  const exitTracked = useRef(false);

  useEffect(() => {
    const trackDepth = marker => {
      trackClientScrollDepth(buzz, {
        pixel_depth: parseInt(scrollY() + window.innerHeight, 10),
        marker,
      });
    };

    const visibilityChangeHandler = () => {
      if (!exitTracked.current && document.visibilityState === 'hidden') {
        trackDepth('distraction_depth');
      }
    };

    const unloadHandler = () => {
      trackDepth('exit_depth');
      exitTracked.current = true;
    };

    window.addEventListener('beforeunload', unloadHandler);
    document.addEventListener('visibilitychange', visibilityChangeHandler);

    return () => {
      window.removeEventListener('beforeunload', unloadHandler);
      document.removeEventListener('visibilitychange', visibilityChangeHandler);
    };
  }, [buzz, exitTracked]);
}
