import { useEffect, useContext } from 'react';
import { trackClientPageview } from './client-event-tracking';
import { trackComscorePageview } from './comscore/client';
import { trackFacebookPageView } from './facebook/client';
import { trackOutbrainPageView } from './outbrain/client';
import { trackPermutivePageView } from './permutive/client';
import { trackYahooPageview } from './yahoo/client';
import { getReferrer } from '@buzzfeed/bf-utils/lib/referrer';
import ABeagleContext from '../../contexts/abeagle';
import DestinationContext from '../../contexts/destination';
import ConsentContext from '../../contexts/consent';

export function usePageviewTracking(buzz) {
  const { getFeatureFlagValue } = useContext(
    ABeagleContext
  );
  const { destination } = useContext(DestinationContext);
  const isPermutiveEnabled = getFeatureFlagValue('ADSGROUP-442-permutive');
  const {tracking: { consentValue, isConsentReady }} = useContext(ConsentContext);

  useEffect(() => {
    trackClientPageview(buzz);

    // Facebook will check consent independently
    trackFacebookPageView(destination, buzz, {
      section: buzz.classification.section,
      Edition: buzz.classification.edition,
      username: buzz.bylines[0].username,
      tags: buzz.tags,
      SocialReferral: getReferrer(),
    });
  }, [buzz, destination]);

  useEffect(() => {
    if (isPermutiveEnabled) {
      // Permutive will check consent independently
      trackPermutivePageView(buzz);
    }
  }, [buzz, isPermutiveEnabled]);

  useEffect(() => {
    if (isConsentReady && consentValue) {
      trackOutbrainPageView();
      if (buzz.classification.edition === 'Japan') {
        trackYahooPageview();
      }
    }
  }, [buzz, consentValue, destination, isConsentReady]);

  useEffect(() => {
    trackComscorePageview();
  }, [buzz]);
}
